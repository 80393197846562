.page-title-area {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 400px;
  text-align: center;
  position: relative;
}

.page-title-area:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: #0046c0;
}

[dir="rtl"] .page-title-item ul li {
  margin-right: 0;
  margin-left: 25px;
}

[dir="rtl"] .page-title-item ul li:last-child {
  margin-left: 0;
}

[dir="rtl"] .page-title-item ul li::before {
  right: auto;
  left: -17px;
}

.page-title-item {
  position: relative;
}

.page-title-item h2 {
  color: #f8f8f8;
  font-weight: 700;
  font-size: 48px;
  margin-bottom: 20px;
}

.page-title-item ul {
  margin: 0;
  padding: 0;
}

.page-title-item ul li {
  list-style-type: none;
  display: inline-block;
  color: #f8f8f8;
  font-weight: 600;
  font-size: 18px;
  margin-right: 25px;
  position: relative;
}

.page-title-item ul li:last-child {
  margin-right: 0;
}

.page-title-item ul li a {
  display: inline-block;
  color: #f8f8f8;
}

.page-title-item ul li a:hover {
  color: #111111;
}

.page-title-item ul li::before {
  content: ">";
  position: absolute;
  right: -17px;
  top: 1px;
}

.page-title-item ul li:last-child::before {
  display: none;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  vertical-align: middle;
}

.page-title-one {
  background-image: url("/images/page-banner1.jpg");
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

/*-- Header Top --*/
[dir="rtl"] .header-top-item .header-top-right {
  text-align: left;
}

[dir="rtl"] .header-top-item .header-top-right .lang-list {
  margin-right: 0;
  margin-left: 30px;
}

[dir="rtl"] .header-top-item .header-top-right ul li {
  margin-left: 5px;
  margin-right: 0;
}

[dir="rtl"] .header-top-item .header-top-right ul li:last-child {
  margin-left: 0;
}

[dir="rtl"] .header-top-item .header-top-left ul li {
  margin-left: 25px;
  margin-right: 0;
}

[dir="rtl"] .header-top-item .header-top-left ul li:last-child {
  margin-left: 0;
}

.header-top {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f2f2f2;
  background-color: #ffffff;
  z-index: 1;
}

.header-top-item .header-top-left ul {
  margin: 0;
  padding: 0;
}

.header-top-item .header-top-left ul li {
  display: inline-block;
  list-style-type: none;
  font-weight: 500;
  font-size: 14px;
  color: #344c5d;
  margin-right: 25px;
}

.header-top-item .header-top-left ul li i {
  color: #0046c0;
  font-size: 18px;
  position: relative;
  top: 2px;
  margin-right: 6px;
}

.header-top-item .header-top-left ul li:last-child {
  margin-right: 0;
}

.header-top-item .header-top-left ul li a {
  display: inline-block;
  color: #4a6f8a;
}

.header-top-item .header-top-left ul li a:hover {
  color: #0046c0;
}

.header-top-item .header-top-left ul li a i {
  color: #0046c0;
  font-size: 18px;
  position: relative;
  top: 2px;
  margin-right: 6px;
}

.header-top-item .header-top-right {
  text-align: right;
}

.header-top-item .header-top-right .lang-list {
  margin-right: 30px;
  position: relative;
  top: -2px;
}

.header-top-item .header-top-right .lang-list li a {
  padding: 0 10px;
  width: auto;
  height: auto;
  font-size: 13px;
}

.header-top-item .header-top-right ul {
  margin: 0;
  padding: 0;
  display: inline-block;
}

.header-top-item .header-top-right ul li {
  list-style-type: none;
  display: inline-block;
  margin-right: 10px;
}

.header-top-item .header-top-right ul li:last-child {
  margin-right: 0;
}

.header-top-item .header-top-right ul li a {
  display: block;
  width: 25px;
  height: 25px;
  line-height: 25px;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  border: 1px solid transparent;
  background-color: #0046c0;
}

.header-top-item .header-top-right ul li a:hover {
  color: #0046c0;
  background-color: transparent;
  border: 1px solid #0046c0;
}
/*-- End Header Top --*/

.main-nav nav .navbar-nav {
  margin-right: auto;
  margin-left: auto;
}

.main-nav nav .navbar-nav .nav-item a {
  font-weight: 500;
  font-size: 15px;
  color: #344c5d;
  text-transform: capitalize;
}

[dir="rtl"] .footer-item .footer-contact ul li {
  padding-right: 60px;
  padding-left: 0;
}

[dir="rtl"] .footer-item .footer-contact ul li i {
  right: 0;
  left: auto;
}

/*-- Footer --*/
footer {
  background-image: url("/images/footer-bg.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #0843aa;
  position: relative;
}

.footer-item {
  margin-bottom: 30px;
}

.footer-item .footer-contact h3 {
  color: #ffffff;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 30px;
}

.footer-item .footer-contact ul {
  margin: 0;
  padding: 0;
}

.footer-item .footer-contact ul li {
  list-style-type: none;
  display: block;
  color: #ffffff;
  font-weight: 500;
  font-size: 15px;
  padding-left: 60px;
  position: relative;
  margin-bottom: 30px;
}

.footer-item .footer-contact ul li:last-child {
  margin-bottom: 0;
  margin-left: 0;
}

.footer-item .footer-contact ul li:last-child i {
  top: 2px;
}

.footer-item .footer-contact ul li a {
  display: block;
  color: #ffffff;
  margin-bottom: 7px;
}

.footer-item .footer-contact ul li a:last-child {
  margin-bottom: 0;
}

.footer-item .footer-contact ul li a:hover {
  margin-left: 5px;
}

.footer-item .footer-contact ul li a:hover i {
  left: 5px;
}

.footer-item .footer-contact ul li i {
  position: absolute;
  top: 2px;
  left: 0;
  font-size: 42px;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
}

.footer-item .footer-quick h3 {
  color: #ffffff;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 30px;
}

.footer-item .footer-quick ul {
  margin: 0;
  padding: 0;
}

.footer-item .footer-quick ul li {
  list-style-type: none;
  display: block;
  margin-bottom: 20px;
}

.footer-item .footer-quick ul li:last-child {
  margin-bottom: 0;
}

.footer-item .footer-quick ul li a {
  display: block;
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;
}

.footer-item .footer-quick ul li a:hover {
  margin-left: 5px;
}

.footer-item .footer-feedback h3 {
  color: #ffffff;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 30px;
}

.footer-item .footer-feedback .form-group {
  margin-bottom: 20px;
}

.footer-item .footer-feedback .form-group .form-control {
  font-size: 15px;
  background-color: transparent;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid #ffffffc7;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 15px;
  color: #ffffff;
}

.footer-item .footer-feedback .form-group .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: 1px solid #ffffffc7;
}

.footer-item .footer-feedback .form-group ::-webkit-input-placeholder {
  color: #ffffffc7;
}

.footer-item .footer-feedback .form-group :-ms-input-placeholder {
  color: #ffffffc7;
}

.footer-item .footer-feedback .form-group ::-ms-input-placeholder {
  color: #ffffffc7;
}

.footer-item .footer-feedback .form-group ::placeholder {
  color: #ffffffc7;
}

.footer-item .footer-feedback .feedback-btn {
  font-weight: 600;
  font-size: 14px;
  background-color: #ffffff;
  padding: 12px 35px;
  border-radius: 30px;
  color: #0046c0;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
}

.footer-item .footer-feedback .feedback-btn:hover {
  color: #ffffff;
  background-color: #111111;
}
/*-- End Footer --*/

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}
.pt-100 {
  padding-top: 100px;
}
.pb-100 {
  padding-bottom: 100px;
}
.pb-70 {
  padding-bottom: 70px;
}

[dir="rtl"] .blog-details-item .blog-details-search .blog-details-btn {
  left: 0;
  right: auto;
}

[dir="rtl"] .blog-details-item .blog-details-recent ul li {
  padding-right: 120px;
  padding-left: 0;
}

[dir="rtl"] .blog-details-item .blog-details-recent ul li img {
  right: 0;
  left: auto;
}

[dir="rtl"] .blog-details-item .blog-details-recent ul li ul li {
  padding-right: 0;
  margin-left: 25px;
  margin-right: 0;
}

[dir="rtl"] .blog-details-item .blog-details-recent ul li ul li:last-child {
  margin-left: 0;
}

[dir="rtl"] .blog-details-item .blog-details-recent ul li ul li i {
  margin-left: 2px;
  margin-right: 0;
}

[dir="rtl"] .blog-details-item .blog-details-category ul li {
  padding-right: 20px;
  padding-left: 0;
}

[dir="rtl"] .blog-details-item .blog-details-category ul li:before {
  right: 0;
  left: auto;
}

[dir="rtl"]
  .blog-details-item
  .blog-details-previous
  .prev-next
  ul
  li:last-child {
  float: left;
}

[dir="rtl"] .blog-details-item .blog-details-img ul li {
  margin-left: 50px;
  margin-right: 0;
}

[dir="rtl"] .blog-details-item .blog-details-img ul li:last-child {
  margin-left: 0;
}

/*-- Blog Details --*/
.blog-details-item {
  margin-bottom: 30px;
}

.blog-details-item .blog-details-img img {
  width: 100%;
  margin-bottom: 30px;
}

.blog-details-item .blog-details-img h2 {
  color: #232323;
  font-weight: 600;
  font-size: 28px;
  margin-bottom: 16px;
  line-height: 1.4;
}

.blog-details-item .blog-details-img ul {
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}

.blog-details-item .blog-details-img ul li {
  list-style-type: none;
  display: inline-block;
  color: #3a5ed3;
  font-size: 16px;
  margin-right: 50px;
}

.blog-details-item .blog-details-img ul li a {
  font-weight: 400;
  color: #0046c0;
  display: inline-block;
}

.blog-details-item .blog-details-img ul li a:hover {
  color: #111111;
}

.blog-details-item .blog-details-img ul li:last-child {
  margin-right: 0;
}

.blog-details-item .blog-details-img ul li i {
  display: inline-block;
  font-size: 20px;
  margin-right: 5px;
}

.blog-details-item .blog-details-img p {
  font-size: 15px;
  margin-bottom: 22px;
}

.blog-details-item .blog-details-previous h3 {
  color: #232323;
  font-weight: 700;
  margin-top: 10px;
  font-size: 15px;
  margin-bottom: 25px;
}

.blog-details-item .blog-details-previous ul {
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
}

.blog-details-item .blog-details-previous ul li {
  list-style-type: none;
  display: block;
  margin-bottom: 6px;
}

.blog-details-item .blog-details-previous ul li:last-child {
  margin-bottom: 0;
}

.blog-details-item .blog-details-previous .prev-next ul {
  margin: 0;
  padding: 0;
}

.blog-details-item .blog-details-previous .prev-next ul li {
  list-style-type: none;
  display: inline-block;
}

.blog-details-item .blog-details-previous .prev-next ul li:last-child {
  float: right;
}

.blog-details-item .blog-details-previous .prev-next ul li a {
  display: block;
  font-size: 15px;
  color: #0045be;
  border: 1px solid #0045be;
  padding: 10px 25px;
  border-radius: 6px;
}

.blog-details-item .blog-details-previous .prev-next ul li a:hover {
  color: #ffffff;
  border: 1px solid #344c5d;
  background-color: #344c5d;
}

.blog-details-item .blog-details-search {
  position: relative;
  margin-bottom: 40px;
}

.blog-details-item .blog-details-search .form-control {
  height: 50px;
  border-radius: 6px;
  border: 1px solid #0046c0;
  padding-left: 25px;
}

.blog-details-item .blog-details-search .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.blog-details-item .blog-details-search .blog-details-btn {
  color: #ffffff;
  background-color: #0046c0;
  border-radius: 6px;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 17px;
  padding: 11px 20px;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
}

.blog-details-item .blog-details-search .blog-details-btn:hover {
  background-color: #344c5d;
}

.blog-details-item .blog-details-search ::-webkit-input-placeholder {
  color: #344c5d;
}

.blog-details-item .blog-details-search :-ms-input-placeholder {
  color: #344c5d;
}

.blog-details-item .blog-details-search ::-ms-input-placeholder {
  color: #344c5d;
}

.blog-details-item .blog-details-search ::placeholder {
  color: #344c5d;
}

.blog-details-item .blog-details-recent {
  margin-bottom: 40px;
}

.blog-details-item .blog-details-recent h3 {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 30px;
}

.blog-details-item .blog-details-recent ul {
  margin: 0;
  padding: 0;
}

.blog-details-item .blog-details-recent ul li {
  list-style-type: none;
  display: block;
  position: relative;
  padding-left: 120px;
  padding-bottom: 25px;
}

.blog-details-item > .blog-details-recent > ul > li {
  min-height: 115px;
}

.blog-details-item .blog-details-recent ul li:last-child {
  padding-bottom: 0;
}

.blog-details-item .blog-details-recent ul li img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 95px;
}

.blog-details-item .blog-details-recent ul li a {
  display: block;
  font-weight: 600;
  font-size: 15px;
  color: #232323;
  margin-bottom: 15px;
  padding-top: 4px;
}

.blog-details-item .blog-details-recent ul li a:hover {
  color: #0046c0;
}

.blog-details-item .blog-details-recent ul li ul li {
  display: inline-block;
  padding-left: 0;
  color: #3a5ed3;
  font-size: 15px;
  margin-right: 25px;
  padding-bottom: 0;
}

.blog-details-item .blog-details-recent ul li ul li a {
  font-weight: 400;
  color: #0046c0;
  display: inline-block;
}

.blog-details-item .blog-details-recent ul li ul li a:hover {
  color: #111111;
}

.blog-details-item .blog-details-recent ul li ul li i {
  font-size: 20px;
  margin-right: 2px;
  display: inline-block;
}

.blog-details-item .blog-details-recent ul li ul li:last-child {
  margin-right: 0;
}

.blog-details-item .blog-details-category {
  margin-bottom: 40px;
}

.blog-details-item .blog-details-category h3 {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 30px;
}

.blog-details-item .blog-details-category ul {
  margin: 0;
  padding: 0;
}

.blog-details-item .blog-details-category ul li {
  list-style-type: none;
  display: block;
  border-bottom: 1px solid #dee8f5;
  position: relative;
  padding-left: 20px;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.blog-details-item .blog-details-category ul li:hover a {
  color: #ffffff;
}

.blog-details-item .blog-details-category ul li:hover:before {
  width: 100%;
}

.blog-details-item .blog-details-category ul li:before {
  position: absolute;
  content: "";
  left: 0;
  top: -3px;
  width: 3px;
  height: 30px;
  z-index: -1;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
  background-color: #2362bc;
}

.blog-details-item .blog-details-category ul li:last-child {
  margin-bottom: 0;
}

.blog-details-item .blog-details-category ul li a {
  display: block;
  z-index: 1;
  color: #344c5d;
  font-weight: 500;
}

.blog-details-item .blog-details-tags h3 {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 30px;
}

.blog-details-item .blog-details-tags ul {
  margin: 0;
  padding: 0;
}

.blog-details-item .blog-details-tags ul li {
  list-style-type: none;
  display: inline-block;
  margin-bottom: 8px;
  margin-right: 4px;
}

.blog-details-item .blog-details-tags ul li a {
  display: block;
  font-size: 15px;
  color: #344c5d;
  background-color: #e5e5e5;
  padding: 12px 25px;
  border-radius: 8px;
}

.blog-details-item .blog-details-tags ul li a:hover {
  color: #ffffff;
  background-color: #344c5d;
}

.blog-details-form {
  position: relative;
}

.blog-details-form .blog-details-shape {
  position: absolute;
  top: 130px;
  right: 0;
  width: 360px;
  -webkit-animation: a-six 5s infinite linear;
  animation: a-six 5s infinite linear;
}

.blog-details-form .blog-details-form-wrap {
  max-width: 770px;
}

.blog-details-form .blog-details-form-wrap h2 {
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 30px;
}

.blog-details-form .blog-details-form-wrap .form-group {
  margin-bottom: 30px;
}

.blog-details-form .blog-details-form-wrap .form-group textarea {
  height: auto !important;
  padding: 20px;
  z-index: 1;
  position: relative;
}

.blog-details-form .blog-details-form-wrap .form-group .form-control {
  height: 50px;
  padding-left: 20px;
  border-radius: 6px;
  border: 0;
  background-color: #fafafa;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.blog-details-form
  .blog-details-form-wrap
  .form-group
  ::-webkit-input-placeholder {
  color: #344c5d;
}

.blog-details-form .blog-details-form-wrap .form-group :-ms-input-placeholder {
  color: #344c5d;
}

.blog-details-form .blog-details-form-wrap .form-group ::-ms-input-placeholder {
  color: #344c5d;
}

.blog-details-form .blog-details-form-wrap .form-group ::placeholder {
  color: #344c5d;
}

.blog-details-form .blog-details-form-wrap .blog-details-form-btn {
  color: #ffffff;
  font-weight: 600;
  font-size: 15px;
  padding: 15px 34px;
  background-color: #0046c0;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
}

.blog-details-form .blog-details-form-wrap .blog-details-form-btn:hover {
  color: #ffffff;
  background-color: #344c5d;
}
/*-- End Blog Details --*/

[dir="rtl"] .about-item ul li i {
  margin-right: 0;
  margin-left: 10px;
}

/*-- About --*/
.about-item {
  margin-bottom: 30px;
}

.about-item h2 {
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 20px;
}

.about-item p {
  margin-bottom: 25px;
}

.about-item ul {
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
}

.about-item ul li {
  list-style-type: none;
  display: block;
  font-size: 18px;
  font-weight: 500;
  color: #111111;
  margin-bottom: 12px;
}

.about-item ul li:last-child {
  margin-bottom: 0;
}

.about-item ul li i {
  display: inline-block;
  margin-right: 10px;
  color: #0046c0;
  font-size: 23px;
  position: relative;
  top: 2px;
}

.about-item a {
  display: inline-block;
  font-size: 15px;
  color: #ffffff;
  background-color: #0046c0;
  font-weight: 500;
  padding: 20px 45px;
  border-radius: 5px;
}

.about-item a:hover {
  color: 15px;
  background-color: #111111;
}

.about-left {
  position: relative;
}

.about-left img {
  width: 100%;
  z-index: 1;
  border-radius: 10px;
  -webkit-animation: a-seven 10s infinite linear;
  animation: a-seven 10s infinite linear;
}

.about-left:before {
  position: absolute;
  content: "";
  top: -10px;
  left: -10px;
  width: 430px;
  height: 375px;
  border-radius: 10px;
  z-index: -1;
  -webkit-animation: a-one 5s infinite linear;
  animation: a-one 5s infinite linear;
  background-color: #0046c0;
}

.about-left:after {
  position: absolute;
  content: "";
  bottom: -10px;
  right: -10px;
  width: 430px;
  height: 375px;
  border-radius: 10px;
  z-index: -1;
  -webkit-animation: a-two 5s infinite linear;
  animation: a-two 5s infinite linear;
  background-color: #0046c0;
}

@-webkit-keyframes a-one {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(-20px, -20px);
    transform: translate(-20px, -20px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@keyframes a-one {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(-20px, -20px);
    transform: translate(-20px, -20px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@-webkit-keyframes a-two {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(20px, 20px);
    transform: translate(20px, 20px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@keyframes a-two {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(20px, 20px);
    transform: translate(20px, 20px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

.about-right {
  padding-left: 40px;
  position: relative;
}

.about-right img {
  position: absolute;
  top: -55px;
  right: 0;
  z-index: -1;
  width: 500px;
  -webkit-animation: a-three 5s infinite linear;
  animation: a-three 5s infinite linear;
}

@-webkit-keyframes a-three {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(0, 35px);
    transform: translate(0, 35px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@keyframes a-three {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(0, 35px);
    transform: translate(0, 35px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
/*-- End About --*/

.dynamic-Content img {
  max-width: 100%;
}

/* ReactQuill Editor - Supported classes */
.ql-align-center {
  text-align: center;
}
.ql-align-right {
  text-align: right;
}
.ql-align-justify {
  text-align: justify;
}
.ql-editor blockquote {
  border-left: 4px solid #ccc;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
}
/* END ReactQuill Editor - Supported classes */

// Responsive
@media only screen and (max-width: 767px) {
  .page-title-area {
    height: 270px;
  }

  .page-title-item h2 {
    font-size: 26px;
    margin-bottom: 16px;
  }
  .page-title-item ul li {
    font-size: 15px;
    margin-right: 0;
    margin-left: 0;
  }
  .page-title-item ul li i {
    font-size: 17px;
  }
  .page-title-item-two {
    text-align: center;
    max-width: 100%;
  }
  .page-title-item-two h2 {
    font-size: 30px;
    margin-bottom: 10px;
  }
  .page-title-item-two h3 {
    font-size: 22px;
    margin-bottom: 12px;
  }
  .page-title-item-two p {
    font-size: 16px;
  }

  .header-top {
    padding-top: 15px;
    border-bottom: 1px solid #f2f2f2;
    text-align: center;
  }
  .header-top-item .header-top-left ul li {
    font-size: 13px;
    margin-right: 15px;
    margin-bottom: 8px;
  }
  .header-top-item .header-top-right {
    text-align: center;
  }
  .header-top-item .header-top-right .lang-list {
    margin-right: 15px;
  }

  [dir="rtl"] .header-top-item .header-top-left ul li {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  [dir="rtl"] .header-top-item .header-top-right {
    text-align: center;
  }

  /*-- Footer --*/
  .footer-item .footer-contact h3 {
    font-size: 18px;
    margin-bottom: 30px;
  }
  .footer-item .footer-contact ul li {
    font-size: 14px;
    padding-left: 55px;
    margin-bottom: 40px;
  }
  .footer-item .footer-contact ul li:last-child i {
    top: -1px;
  }
  .footer-item .footer-contact ul li i {
    top: 2px;
    font-size: 38px;
  }
  .footer-item .footer-quick h3 {
    font-size: 18px;
    margin-bottom: 30px;
  }
  .footer-item .footer-quick ul li {
    margin-bottom: 15px;
  }
  .footer-item .footer-quick ul li a {
    font-size: 14px;
  }
  .footer-item .footer-feedback h3 {
    font-size: 18px;
    margin-bottom: 30px;
  }
  .footer-item .footer-feedback .form-group .form-control {
    font-size: 14px;
  }
  .footer-item .footer-feedback .feedback-btn {
    font-size: 13px;
    padding: 10px 32px;
  }
  /*-- End Footer --*/

  /*-- Blog Details --*/
  .blog-details-item .blog-details-img img {
    margin-bottom: 30px;
  }
  .blog-details-item .blog-details-img h2 {
    font-size: 20px;
    padding-right: 0;
  }
  .blog-details-item .blog-details-img ul {
    margin-bottom: 25px;
  }
  .blog-details-item .blog-details-img ul li {
    font-size: 14px;
    margin-right: 25px;
  }
  .blog-details-item .blog-details-img ul li i {
    font-size: 18px;
  }
  .blog-details-item .blog-details-img p {
    font-size: 14px;
    margin-bottom: 20px;
  }
  .blog-details-item .blog-details-previous h3 {
    font-size: 14px;
  }
  .blog-details-item .blog-details-previous ul {
    margin-bottom: 30px;
  }
  .blog-details-item .blog-details-previous .prev-next ul {
    margin: 0;
    padding: 0;
  }
  .blog-details-item .blog-details-previous .prev-next ul li a {
    font-size: 14px;
    padding: 6px 18px;
  }
  .blog-details-item .blog-details-search {
    margin-bottom: 30px;
  }
  .blog-details-item .blog-details-search .form-control {
    height: 45px;
    padding-left: 25px;
  }
  .blog-details-item .blog-details-search .blog-details-btn {
    font-size: 14px;
  }
  .blog-details-item .blog-details-recent {
    margin-bottom: 30px;
  }
  .blog-details-item .blog-details-recent h3 {
    font-size: 20px;
    margin-bottom: 25px;
  }
  .blog-details-item .blog-details-recent ul li {
    padding-bottom: 15px;
  }
  .blog-details-item .blog-details-recent ul li a {
    font-size: 13px;
    margin-bottom: 10px;
    padding-top: 2px;
  }
  .blog-details-item .blog-details-recent ul li ul li {
    font-size: 12px;
    margin-right: 12px;
  }
  .blog-details-item .blog-details-recent ul li ul li i {
    font-size: 15px;
    margin-right: 1px;
  }
  .blog-details-item .blog-details-category {
    margin-bottom: 30px;
  }
  .blog-details-item .blog-details-category h3 {
    font-size: 20px;
    margin-bottom: 25px;
  }
  .blog-details-item .blog-details-tags h3 {
    font-size: 20px;
    margin-bottom: 25px;
  }
  .blog-details-item .blog-details-tags ul li a {
    font-size: 14px;
    padding: 10px 20px;
  }
  .blog-details-form .blog-details-shape {
    top: -33px;
    width: 110px;
  }
  .blog-details-form .blog-details-form-wrap {
    max-width: 770px;
  }
  .blog-details-form .blog-details-form-wrap h2 {
    font-size: 20px;
    margin-bottom: 25px;
  }
  .blog-details-form .blog-details-form-wrap .form-group .form-control {
    font-size: 14px;
  }
  .blog-details-form .blog-details-form-wrap .blog-details-form-btn {
    font-size: 14px;
    padding: 13px 25px;
  }
  /*-- End Blog Details --*/

  /*-- About --*/
  .about-item h2 {
    font-size: 22px;
    margin-bottom: 8px;
  }
  .about-item p {
    margin-bottom: 20px;
  }
  .about-item ul {
    margin-bottom: 25px;
  }
  .about-item ul li {
    font-size: 15px;
    margin-bottom: 10px;
  }
  .about-item ul li i {
    margin-right: 6px;
    font-size: 18px;
  }
  .about-item a {
    font-size: 14px;
    padding: 15px 30px;
  }
  .about-left:before {
    display: none;
  }
  .about-left:after {
    display: none;
  }
  @-webkit-keyframes a-one {
    50% {
      -webkit-transform: translate(-10px, -10px);
      transform: translate(-10px, -10px);
    }
  }
  @keyframes a-one {
    50% {
      -webkit-transform: translate(-10px, -10px);
      transform: translate(-10px, -10px);
    }
  }
  @-webkit-keyframes a-two {
    50% {
      -webkit-transform: translate(10px, 10px);
      transform: translate(10px, 10px);
    }
  }
  @keyframes a-two {
    50% {
      -webkit-transform: translate(10px, 10px);
      transform: translate(10px, 10px);
    }
  }
  .about-right {
    padding-left: 0;
  }
  .about-right img {
    right: 2px;
    width: 270px;
  }
  .about-left img {
    -webkit-animation: unset;
    animation: unset;
  }
  /*-- End About --*/
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .page-title-item h2 {
    font-size: 35px;
    margin-bottom: 15px;
  }
  .page-title-item ul li {
    font-size: 16px;
    margin-right: 0;
    margin-left: 0;
  }
  .page-title-item-two {
    text-align: center;
    max-width: 100%;
  }
  .page-title-item-two h2 {
    font-size: 38px;
    margin-bottom: 8px;
  }
  .page-title-item-two h3 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  .page-title-item-two p {
    font-size: 16px;
  }

  .header-top-item .header-top-left ul li {
    font-size: 14px;
    margin-right: 18px;
    margin-bottom: 5px;
  }
  .header-top-item .header-top-left ul li i {
    font-size: 16px;
    margin-right: 0;
  }

  [dir="rtl"] .header-top-item .header-top-left ul li {
    margin-left: 0 !important;
  }

  /*-- Footer --*/
  .footer-item .footer-contact h3 {
    font-size: 20px;
    margin-bottom: 30px;
  }
  .footer-item .footer-contact ul li {
    font-size: 15px;
    margin-bottom: 40px;
  }
  .footer-item .footer-contact ul li:last-child i {
    top: 0;
  }
  .footer-item .footer-contact ul li i {
    top: 2px;
    font-size: 38px;
  }
  .footer-item .footer-quick h3 {
    font-size: 20px;
    margin-bottom: 30px;
  }
  .footer-item .footer-quick ul li {
    margin-bottom: 16px;
  }
  .footer-item .footer-feedback h3 {
    font-size: 20px;
    margin-bottom: 30px;
  }
  .footer-item .footer-feedback .form-group .form-control {
    font-size: 15px;
  }
  .footer-item .footer-feedback .feedback-btn {
    font-size: 14px;
    padding: 10px 35px;
  }
  /*-- End Footer --*/

  /*----- Blog Details Page -----*/
  /*-- Blog Details --*/
  .blog-details-item .blog-details-img img {
    margin-bottom: 40px;
  }
  .blog-details-item .blog-details-img h2 {
    font-size: 22px;
    margin-bottom: 15px;
    padding-right: 0;
  }
  .blog-details-item .blog-details-img ul {
    margin-bottom: 25px;
  }
  .blog-details-item .blog-details-img ul li {
    font-size: 15px;
    margin-right: 40px;
  }
  .blog-details-item .blog-details-img ul li i {
    font-size: 18px;
  }
  .blog-details-item .blog-details-img p {
    font-size: 14px;
    margin-bottom: 20px;
  }
  .blog-details-item .blog-details-previous h3 {
    font-size: 15px;
    margin-bottom: 15px;
  }
  .blog-details-item .blog-details-previous ul {
    margin-bottom: 30px;
  }
  .blog-details-item .blog-details-previous .prev-next ul li a {
    font-size: 15px;
    padding: 8px 20px;
  }
  .blog-details-item .blog-details-search {
    margin-bottom: 30px;
  }
  .blog-details-item .blog-details-recent {
    margin-bottom: 40px;
  }
  .blog-details-item .blog-details-recent h3 {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 30px;
  }
  .blog-details-item .blog-details-recent ul li a {
    font-size: 15px;
    padding-top: 15px;
  }
  .blog-details-item .blog-details-recent ul li ul li {
    font-size: 15px;
  }
  .blog-details-item .blog-details-category {
    margin-bottom: 40px;
  }
  .blog-details-form .blog-details-shape {
    position: absolute;
    top: 130px;
    right: 0;
    width: 360px;
  }
  .blog-details-form .blog-details-form-wrap h2 {
    font-size: 26px;
    margin-bottom: 25px;
  }
  .blog-details-form .blog-details-form-wrap .blog-details-form-btn {
    font-size: 15px;
    padding: 12px 34px;
  }
  /*-- End Blog Details --*/
  /*----- End Blog Details Page -----*/

  /*-- About --*/
  .about-item h2 {
    font-size: 26px;
    margin-bottom: 10px;
  }
  .about-item p {
    margin-bottom: 25px;
  }
  .about-item ul {
    margin-bottom: 35px;
  }
  .about-item ul li {
    font-size: 15px;
    margin-bottom: 12px;
  }
  .about-item ul li i {
    margin-right: 8px;
    font-size: 20px;
  }
  .about-item a {
    font-size: 15px;
    padding: 14px 30px;
  }
  .about-left {
    position: relative;
  }
  .about-left img {
    width: 100%;
    z-index: 1;
  }
  .about-left:before {
    position: absolute;
    content: "";
    top: -10px;
    left: -10px;
    width: 430px;
    height: 375px;
    border-radius: 10px;
    z-index: -1;
    -webkit-animation: a-one 5s infinite linear;
    animation: a-one 5s infinite linear;
    background-color: #0046c0;
  }
  .about-left:after {
    position: absolute;
    content: "";
    bottom: -10px;
    right: -10px;
    width: 430px;
    height: 375px;
    border-radius: 10px;
    z-index: -1;
    -webkit-animation: a-two 5s infinite linear;
    animation: a-two 5s infinite linear;
    background-color: #0046c0;
  }
  @-webkit-keyframes a-one {
    50% {
      -webkit-transform: translate(-10px, 10px);
      transform: translate(-10px, 10px);
    }
  }
  @keyframes a-one {
    50% {
      -webkit-transform: translate(-10px, 10px);
      transform: translate(-10px, 10px);
    }
  }
  @-webkit-keyframes a-two {
    50% {
      -webkit-transform: translate(10px, 10px);
      transform: translate(10px, 10px);
    }
  }
  @keyframes a-two {
    50% {
      -webkit-transform: translate(10px, 10px);
      transform: translate(10px, 10px);
    }
  }
  .about-right {
    padding-left: 0;
  }
  .about-right img {
    right: 0;
    width: 390px;
  }
  /*-- End About --*/
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  /*-- Blog Details --*/
  .blog-details-item .blog-details-recent ul li {
    padding-left: 110px;
  }
  .blog-details-item .blog-details-recent ul li a {
    margin-bottom: 4px;
  }
  .blog-details-item .blog-details-recent ul li ul li {
    font-size: 14px;
    margin-right: 2px;
  }
  .blog-details-item .blog-details-recent ul li ul li i {
    font-size: 16px;
    margin-right: 1px;
  }
  /*-- End Blog Details --*/

  [dir="rtl"] .blog-details-item .blog-details-recent ul li {
    padding-left: 0 !important;
  }
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px !important;
  }
}

@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px !important;
  }
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px !important;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px !important;
  }
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px !important;
  }
}

@media only screen and (max-width: 767px) {
  [dir="rtl"] .blog-details-item .blog-details-img ul li {
    margin-left: 25px !important;
    margin-right: 0 !important;
  }
  [dir="rtl"] .blog-details-item .blog-details-recent ul li {
    padding-right: 110px !important;
    padding-left: 0 !important;
  }
}
